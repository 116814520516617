import React from 'react'

import envelop from "../images/envelop.png"
import footerBackground from "../images/footer.png"

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="page" id="footer">
            <div className="left">
                <svg className="footer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 58.085">
                    <defs>
                    </defs>
                    <g transform="translate(-825.529 97.136)">
                        <path fill="#ff5a55"
                              d="M875.529-97.136,863.813-39.051h-13.2l-3.135-22.772L838.73-39.051h-13.2L828-97.136h12.871l-2.31,32.343,7.508-20.462h8.581l2.393,20.462,5.611-32.343Z"
                              transform="translate(0)"/>
                    </g>
                </svg>
                <div className="content">
                    <p className="address"><a href="https://goo.gl/maps/eag2gWLWsHyoPtAd7">Johannes Vermeerstraat 63<br/>
                        1071 DN Amsterdam</a></p>
                    <p className="footer__contact"><a href="mailto:info@woek.nl">info@woek.nl</a><br/>
                        <a href="tel:+31641212967">+31 64 12 12 967</a></p>
                    <ul className="socials">
                        <li></li>
                        <li><a href="https://www.linkedin.com/in/wouter-de-geringel-a7525232/" target="_blank">
                            <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1">
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M11.6120219,49.931694 L1.16120219,49.931694 L1.16120219,18.579235 L11.6120219,18.579235 L11.6120219,49.931694 Z M6.38661202,12.6571038 C2.90300546,12.6571038 0.116120219,9.87021858 0.116120219,6.38661202 C0.116120219,2.90300546 2.90300546,0.116120219 6.38661202,0.116120219 C9.87021858,0.116120219 12.6571038,2.90300546 12.6571038,6.38661202 C12.6571038,9.87021858 9.87021858,12.6571038 6.38661202,12.6571038 Z M49.931694,49.931694 L39.4808743,49.931694 L39.4808743,31.4685792 C39.4808743,28.6818306 37.0423497,26.2431694 34.2554645,26.2431694 C31.4685792,26.2431694 29.0300546,28.6818306 29.0300546,31.4685792 L29.0300546,49.931694 L18.579235,49.931694 L18.579235,18.579235 L29.0300546,18.579235 L29.0300546,22.7595628 C30.7718579,19.9726776 34.6038251,17.8825137 37.739071,17.8825137 C44.3579235,17.8825137 49.931694,23.4562842 49.931694,30.0751366 L49.931694,49.931694 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
                                </g>
                            </svg>
                        </a></li>
                    </ul>
                </div>
            </div>
            <div onClick={this.props.scrollToTop} className="right">
                <svg xmlns="http://www.w3.org/2000/svg" width="719.913" height="852.383" viewBox="0 0 719.913 852.383">
                    <g transform="translate(-811.014 -185.48)">
                        <path fill="#f2f2f2"
                              d="M1530.927,537.088,1406.8,657.335,1257.856,497.514v540.35H1084.085V497.514L932.034,657.335,811.014,537.088,1170.971,185.48Z"
                              transform="translate(0)"/>
                    </g>
                </svg>
            </div>
        </div>
    ;
    }
    }

    export default Footer
