import React from 'react'

import '../sass/_clientage.scss'

class Clientage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [
                '/assets/clients/kpn.jpg',
                '/assets/clients/nis5.jpg',
                '/assets/clients/telfort.jpg',
                '/assets/clients/rijksoverheid.jpg',
                '/assets/clients/rogerbacon.jpg',
                '/assets/clients/mercedes.jpg',
                '/assets/clients/park-mobile.jpg',
                '/assets/clients/park-line.jpg',
                '/assets/clients/grote-prijs.jpg',
                '/assets/clients/abel.jpg',
            ]
        }
    }

    _handleMouseEnter(colors = "#FF0000,#00FF00"){
        if (typeof window !== `undefined`) {
            window.settings.dust_fillStyle = colors;
            window.settings.strokeStyle = colors;
        }
    }


    render() {
        const { clients } = this.state;

        return  <div className="page" id="clientage" onMouseEnter={() => { this._handleMouseEnter()}}>

                <h1>Worked for</h1>

                <div className="clients">
                    { clients.map( (client)=> (
                        <div className="client" key={client}><img src={client}/></div>
                    ) ) }
                </div>

        </div>;
    }
}

export default Clientage
