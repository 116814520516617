import React from 'react'


import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems

// ignore
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin';
import { MorphSVGPlugin } from '../MorphSVGPlugin';


import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import Layout from '../components/layout'
import SEO from '../components/seo'
import Projects from '../components/projects'

// import Contact from "../components/contact";
import Clientage from "../components/clientage";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
// import ScrollIntoContainer from "../components/ScrollIntoContainer";

//without this line, plugins may get dropped by your bundler...
const plugins = [ScrollToPlugin, MorphSVGPlugin];

let r = "color:red;font-weight:bold; background: white; font-family: monospace;";
let b = "color:black;font-weight:bold;background: white; font-family: monospace;";
console.log(`%c
             ###############           
         ######################        
       ########          ########      
     #######       %c&&       %c#######    
    #####/   %c&&   &&&&  &&    %c######   
   #####       %c&&& &&  &&       %c#####  
  #####          %c&&&&&&&         %c##### 
  #####            %c&&&           %c##### 
  ####             %c&&&. &&&      %c##### 
 ####          %c&&&&&&&&&  &&&    %c##### 
  #####     %c&&&   &&&& &&&       %c##### 
  #####.       %c&&&& &&          %c#####  
   #####/        %c&& ,&&        %c######  
    ######       %c&&  &&      %c######    
     #######(     %c&&   &&  %c#######     
       ###########    ##########       
          ####################         
              ############             
`, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r, b, r)

class IndexPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showContactForm: false,
        }
    }


    componentDidMount() {

        if (typeof window !== `undefined`) {
            this.scrl = new this.SmoothScroll(document, 20, 5);

            ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
        }

        // this.loadParalax();
        // this.initHoverTags();

        this.initTimeline();
    }

    SmoothScroll(target, speed, smooth) {
        this.moving = false;

        if (target === document)
            target = (document.scrollingElement
                || document.documentElement
                || document.body.parentNode
                || document.body) // cross browser support for document scrolling

        var frame = target === document.body
            && document.documentElement
            ? document.documentElement
            : target // safari is the new IE

        this.pos = target.scrollTop;

        const scrolled = (e) => {
            e.preventDefault(); // disable default scrolling

            var delta = normalizeWheelDelta(e)

            this.pos += -delta * speed
            this.pos = Math.max(0, Math.min(this.pos, target.scrollHeight - frame.clientHeight)) // limit scrolling


            if (!this.moving) update()
        }

        const normalizeWheelDelta = (e) => {
            if (e.detail) {
                if (e.wheelDelta)
                    return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1) // Opera
                else
                    return -e.detail / 3 // Firefox
            } else
                return e.wheelDelta / 120 // IE,Safari,Chrome
        }

        const update = () => {

            this.moving = true

            var delta = (this.pos - target.scrollTop) / smooth

            target.scrollTop += delta

            if (Math.abs(delta) > 0.8)
                requestFrame(update)
            else
                this.moving = false
        }

        target.addEventListener('mousewheel', scrolled, { passive: false })
        target.addEventListener('DOMMouseScroll', scrolled, { passive: false })



        var requestFrame = function () { // requestAnimationFrame cross browser
            return (
                window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (func) {
                    window.setTimeout(func, 1000 / 50);
                }
            );
        }()

        return this;
    }

    scrollToTop() {
        this.scrl.moving = true;

        TweenMax.to(window, 1.5, {
            scrollTo: 0, onComplete: () => {
                this.scrl.pos = 0;
                this.scrl.moving = false;
            }
        });
    }

    initTimeline() {
        var tl = new TimelineMax();
        tl.to("#letter-w", 1, { x: -100, rotation: 20, y: -100 }, 0);
        tl.to("#letter-o", 1, { x: -50, rotation: 15, y: -320 }, 0);
        tl.to("#letter-e", 1, { x: 50, rotation: -10, y: -80 }, 0);
        tl.to("#letter-k", 1, { x: 120, rotation: -30, y: -200 }, 0);

        var controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            // triggerElement: "#header",
            duration: '70%',
            offset: 0,
        })
            .setTween(tl)
            .addTo(controller);

        new ScrollMagic.Scene({
            duration: 250,	// the scene should last for a scroll distance of 100px
            offset: 200	// start this scene after scrolling for 50px
        })
            .setPin("#intro") // pins the element for the the scene's duration
            .addTo(controller); // assign the scene to the controller

        this.initSkillsAnimation(controller);
    }

    initSkillsAnimation(controller) {
        // let beforeLineAnimation = true;
        // const animationElement = document.getElementById("linePath__animation");
        // const to = animationElement.getAttribute('to');
        // const from = animationElement.getAttribute('from');

        let tl = new TimelineMax();
        tl.call(() => {
            console.log('null');
            console.log(controller.info("scrollDirection"));

            window.settings.forceIcon = controller.info("scrollDirection") == "FORWARD" ? "keyboard" : null;
        },null,null,0.001);
        tl.staggerFromTo("#word span", 0.0001, { autoAlpha: 0, width: 0 }, { autoAlpha: 1, width: "auto" }, 0.02);
        
        tl.staggerFromTo("#coding-list li", 0.0001, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.01);

        //tl.staggerFromTo("#word span", 0.0001, {autoAlpha: 1, width: "auto"}, {autoAlpha: 0, width: 0}, -0.01, "back");
        //tl.staggerFromTo("#coding-list li", 0.001, {autoAlpha: 1}, {autoAlpha: 0}, 0.01, "back");
        tl.fromTo("#type", 0.01, { autoAlpha: 1 }, { autoAlpha: 0 }, "end-coding");
        tl.fromTo("#coding-list", 0.01, { autoAlpha: 1 }, { autoAlpha: 0 }, "end-coding");

        // tl.fromTo("#type", 0.000001, {display:'block'}, {display:'none'},  "start-logic");
        // tl.fromTo("#type2", 0.000001, {display:'none'}, {display:'block'}, "start-logic");
        
        tl.call(() => {
            window.settings.forceIcon = controller.info("scrollDirection") == "FORWARD" ? "brain" : "keyboard";
        })
        tl.fromTo("#type2", 0.01, { autoAlpha: 0 }, { autoAlpha: 1 }, "start-logic");


        tl.fromTo("#LineEllipse1", 0.01, { scale: 0, transformOrigin: "50% 50%" }, { scale: 1 });
        tl.fromTo("#LinePath", 0.02, { scaleX: 0 }, { scaleX: 1, transformOrigin: "0% 50%" });
        tl.fromTo("#LineEllipse2", 0.01, { scale: 0, transformOrigin: "50% 50%" }, { scale: 1 }, "-=0.01");

        tl.staggerFromTo("#logic-list li", 0.0001, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.01);


        /*
        3: soft
         */
        tl.call(() => {
            window.settings.forceIcon = controller.info("scrollDirection") == "FORWARD" ? "heart" : "brain";
        })
        tl.to("#type2", 0.01, { autoAlpha: 0 }, "end-logic");
        tl.to("#logic-list", 0.01, { autoAlpha: 0 }, "end-logic");

        tl.to("#LineEllipse1", 0.01, { scale: 0.0, transformOrigin: "50% 50%" }, "end-logic");
        tl.to("#LineEllipse2", 0.01, { scale: 0.0, transformOrigin: "50% 50%" }, "end-logic");


        tl.fromTo("#type3", 0.01, { autoAlpha: 0 }, { autoAlpha: 1 }, "start-soft");
        tl.to("#LinePath", 0.01, { morphSVG: "M0,0 C78.874,0 78.874,84 157.748,84 C236.622,84 236.62,0 315.493,0 C394.366,0 394.37,84 473.247,84 C552.124,84 552.123,0 631,0" });


        // tl.call(()=>{ beforeLineAnimation = true }, null, null, "start-soft");
        // tl.call(()=>{
        //     if(beforeLineAnimation){
        //         console.log('linePath__animation >');
        //         animationElement.setAttribute('to', to);
        //         animationElement.setAttribute('from', from);
        //         // animationElement.setAttribute('keyPoints', "0;1");
        //         animationElement.beginElement();
        //     }else{
        //         console.log('linePath__animation <');
        //
        //         animationElement.setAttribute('to', from);
        //         animationElement.setAttribute('from', to);
        //
        //         animationElement.beginElement();
        //
        //     }
        // }, null, null, "start-soft");
        // tl.call(()=>{ beforeLineAnimation = false });
        tl.staggerFromTo("#soft-list li", 0.0001, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.01);
        tl.call(() => {
            console.log('null');
            window.settings.forceIcon = null;
            window.settings.forceIcon = controller.info("scrollDirection") == "FORWARD" ? null : "heart";
        })

        new ScrollMagic.Scene({
            triggerElement: "#skills",
            duration: "300%",
            triggerHook: 0,
        })
            .setPin("#skills")
            .setTween(tl)
            .addTo(controller);


    }

    initHoverTags() {
        // tags
        const tags = document.getElementsByClassName("ps-tag");

        Object.entries(tags).map((object) => {
            // Here, object = Array[index, object] (object is the
            // HTML element object). This means that the actual element
            // is stored in object[1], not object. Do whatever you need
            // with it here. In this case we attach a click event:
            // console.log(object[1]);
            object[1].addEventListener("mouseover", function () {
                // Output innerHTML of the clicked element
                console.log("Hello " + this +
                    " (" + this.innerHTML + ") from map method...");
            });
        });
    }

    loadParalax() {
        var script = document.createElement('script');
        script.onload = function () {
            var scene = document.getElementById('header');

            /* eslint-disable */
            window.parallaxJs = new Parallax(scene, {
                selector: '.layer3d',
                relativeInput: false
            });
        };
        script.src = "/parallax.js";

        document.head.appendChild(script);
    }

    render() {
        return (
            <Layout>
                <SEO title="WOEK – a passionate developer based in Amsterdam"
                    keywords={[`gatsby`, `application`, `react`]} />

                <div id="wrapper">
                    <div id="icons"></div>

                    <div id="logo">
                        <img src="/assets/logo-woek-w.svg" alt="" />
                    </div>

                    <div id="contact-button-container">
                        <div id="contact-button" onClick={() => { this.setState({ 'showContactForm': true }) }}>

                            <svg width="59px" height="58px" viewBox="0 0 59 58" xmlns="http://www.w3.org/2000/svg">

                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g>
                                        <g id="Path">
                                            <path fill="#FF5A55"
                                                d="M33.16,0.704 C49.144,0.704 60.052,13.448 58.108,29.216 C56.164,44.984 42.232,57.728 26.14,57.728 C9.292,57.728 -0.86,44.984 1.084,29.216 C3.028,13.448 16.096,0.704 33.16,0.704 Z"
                                                id="path-1"></path>
                                            {/*<use fill="#FF5A55" xlink:href="#path-1"></use>*/}
                                        </g>
                                        <g id="Group" transform="translate(19.103000, 22.635000)"
                                            strokeLinejoin="round">
                                            <g id="Rectangle">
                                                <rect x="0" y="0" width="22.923" height="14.694"></rect>
                                                <rect stroke="#FFFFFF" strokeWidth="2" x="1" y="1" width="20.923"
                                                    height="12.694"></rect>
                                            </g>
                                            <polyline id="Path" stroke="#FFFFFF" strokeWidth="2"
                                                points="1.61 4.047 10.865 8.073 21.812 4.047"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>


                    <div id="header">
                        <div id="ground-plane" className="ground-plane" />

                        <div id="woek" className="logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="734.285"
                                viewBox="0 0 734.285 229.24">
                                <g transform="translate(-1148.773 -93.455)" fill="#ff5a55">
                                    <path id="letter-w"
                                        d="M1345.408,93.455,1299.332,317.6h-51.917l-12.33-87.876L1200.69,317.6h-51.917l9.735-224.147h50.619l-9.085,124.809,29.528-78.961h33.745l9.41,78.961,22.065-124.809Z"
                                        transform="translate(0)" />
                                    <path id="letter-o"
                                        d="M1509.869,238.641c-5.842,46.485-47.7,84.054-96.047,84.054-50.618,0-81.118-37.569-75.279-84.054s45.1-84.055,96.37-84.055C1482.937,154.586,1515.709,192.156,1509.869,238.641Zm-51.917,0c2.6-21.014-10.059-38.207-28.879-38.207s-36.017,17.193-38.613,38.207,10.059,38.207,28.879,38.207S1455.355,259.655,1457.952,238.641Z"
                                        transform="translate(3.615)" />
                                    <path id="letter-e"
                                        d="M1687.392,232.91c-.649,6.686-2.6,16.556-5.191,22.287H1574.149c3.245,12.736,13.629,21.651,33.746,21.651,11.681,0,21.74-4.139,30.826-10.507l27.255,29.929c-15.25,14.645-39.587,26.425-65.544,26.425-52.565,0-83.067-37.569-77.227-84.054s43.157-84.055,94.425-84.055C1663.381,154.586,1693.233,185.151,1687.392,232.91Zm-109.349-10.826H1638.4c-.974-12.1-8.437-21.65-25.31-21.65C1594.591,200.434,1583.559,209.349,1578.043,222.084Z"
                                        transform="translate(7.147)" />
                                    <path id="letter-k"
                                        d="M1802.486,230.044l55.485,87.558h-62.3l-29.526-51.261-12.331,12.417-4.866,38.844H1697.03l27.906-224.147h51.916L1762.25,211.9l48.023-52.216h62.3Z"
                                        transform="translate(10.485)" />
                                </g>
                            </svg>
                        </div>

                        <div id="intro" className="lead element-repeller" data-depth="0.6">
                            <p>Freelance front-end developer with a <span className="ps-tag"
                                data-ps-tag="heart">heart</span> for coding, a
brain for logic and an eye
                                for design.</p>
                        </div>
                    </div>


                    <div id="about" className="page">
                        <div className="content">
                            <p id="elementRepeller01">
                                Woek is pretty much Wouter de Geringel, a freelance developer who started
                                out as a molecular biologist but, after an internship modeling heart cell behaviour in
                                C++, started to prefer the predictable order of computercode over the more choatic order
                                of DNA-code.
                            </p>
                        </div>
                    </div>

                    <div id="skills" className="page">
                        <div className="content">
                            <h2 id="type">
                                <span id="word">
                                    <span>C</span>
                                    <span>o</span>
                                    <span>d</span>
                                    <span>i</span>
                                    <span>n</span>
                                    <span>g</span>
                                </span>
                                <span className="cursor"></span>
                            </h2>
                            <h2 id="type2">
                                Logic
                            </h2>
                            <h2 id="type3">Soft</h2>
                            <h2>Skills</h2>

                            <div id="line">

                                {/*<svg  viewBox="0 0 539 56">*/}
                                {/*    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
                                {/*        <g id="logic-line">*/}
                                {/*            <circle id="LineEllipse1" fill="#F2F2F2" cx="27.898" cy="27.899" r="27.898"></circle>*/}
                                {/*            <circle id="LineEllipse2" fill="#F2F2F2" cx="510.737" cy="27.899" r="27.898"></circle>*/}
                                {/*            <path d="M510.737,28.117 L28.418,28.117 L510.737,28.117 Z" id="LinePath" stroke="#F2F2F2" strokeWidth="14"></path>*/}
                                {/*        </g>*/}
                                {/*    </g>*/}
                                {/*</svg>*/}

                                <svg viewBox="0 0 645 98" version="1.1">
                                    <g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
                                        <g id="logic-line" transform="translate(7.000000, 7.000000)">
                                            {/*<path stroke="#F2F2F2" stroke-linecap="round" strokeWidth="14" d="M0,0 C78.874,0 78.874,84 157.748,84 C236.622,84 236.62,0 315.493,0 C394.366,0 394.37,84 473.247,84 C552.124,84 552.123,0 631,0" id="wiggle"></path>*/}
                                            <path id="LinePath" d="M74,41.5 C134.374235,41.5 134.374235,42.5 194.748469,42.5 C255.122704,42.5 255.121173,41.5 315.494642,41.5 C375.868111,41.5 375.871173,42.5 436.247704,42.5 C496.624235,42.5 496.623469,41.5 557,41.5" stroke="#F2F2F2" strokeWidth="14" strokeLinecap="round">

                                            </path>
                                            <circle id="LineEllipse1" fill="#F2F2F2" cx="74.461" cy="41.899" r="27.898"></circle>
                                            <circle id="LineEllipse2" fill="#F2F2F2" cx="556.3" cy="41.899" r="27.898"></circle>
                                        </g>
                                    </g>
                                </svg>

                            </div>

                            <ul id="coding-list">
                                <li>Html/Css</li>
                                <li>Javascript</li>
                                <li>React</li>
                                <li>React native</li>
                                <li>Web GL</li>
                                <li>Android</li>
                                <li>Laravel</li>
                            </ul>
                            <ul id="logic-list">
                                <li>Scientific background</li>
                                <li>Problem solving</li>
                                <li>UX</li>
                            </ul>

                            <ul id="soft-list">
                                <li>Independent</li>
                                <li>Teamplayer</li>
                                <li>Flexible</li>
                                <li>Pragmatic</li>
                            </ul>
                        </div>
                    </div>

                    <Projects fromHome={true} />

                    <Clientage />

                    <Footer scrollToTop={this.scrollToTop.bind(this)} />
                </div>

                {this.state.showContactForm && <Contact showForm={this.state.showContactForm} onClose={() => { this.setState({ 'showContactForm': false }) }} />}
            </Layout>
        )
    }
}

export default IndexPage
